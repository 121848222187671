import React ,{useEffect,useState, useRef}from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import TopicSlide from "./topicSlide";
import useIsMobile from "../../hooks/useIsMobile";
import { Constants } from "../../helpers/constants";
import {configuration} from "../../helpers/config";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar } from "swiper";
SwiperCore.use([Navigation, Pagination, Scrollbar]);
import 'swiper/css';


const gridCol = {
  1: 12,
  2: 6,
  3: 4,
  4: 3,
};
// component to show cms link and other links in a carousel based card format
export default function TopicSlider({ data, showHeading,pageTitle="", type = "Static",image,column = 1,showThreeColumnData=false,cta }) {
   
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: column,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: column,
    },
    tablet: {
      breakpoint: { max: 1024, min: 640 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 640, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
    },
  };
  const [displayedData, setDisplayedData] = useState(data); // State to store data to render
  const [activeIndex, setActiveIndex] = useState(5);

  // Function to fetch data from the API based on the current slide index
  const fetchData = async (index) => {
    try {
      const response = await fetch(
        `${configuration.APP_URL_CONTENTS}/home-page/listing?module=placeholder&title=${pageTitle}&page=1&limit=${index}`
      );
      const cmsResponse = await fetch(
        `${configuration.APP_URL_CONTENTS}/cms-pages?lob=1`
      );
      const newData = await response.json();
      const datacms = await cmsResponse.json();
      const newDataPages = newData.data[0].pages;  // Extract array from newData
  
      // Filter datacms to find matching IDs in newDataPages
      const matchingData = newDataPages.map(newPage => 
        datacms.find(cmsItem => cmsItem._id === newPage._id)
      ).filter(item => item !== undefined);
      // Uncomment below to use fetched data in state:
      // setDisplayedData(matchingData);
      if(!showThreeColumnData){
        setDisplayedData(matchingData);
       }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Call fetchData on component mount (page load)
  useEffect(() => {
    fetchData(activeIndex);

  }, [activeIndex]);


    // Ensure swiper state is recalculated after data update
    useEffect(() => {
      const swiperInstance = swiperRef.current;
      if (swiperInstance) {
        swiperInstance.update();  // Force Swiper to update its internal state
        setIsAtStart(swiperInstance.isBeginning);
        setIsAtEnd(swiperInstance.isEnd);
      }
    }, [displayedData]);

  // const handleSlideChange = (nextSlide) => {
  //   setActiveIndex((prevIndex) => prevIndex + 5); // Increment index by 5 on each slide
  // };

  const handleSlideChange = (nextSlide) => {
    const swiperInstance = swiperRef.current;
    if (swiperInstance) {
      setIsAtStart(swiperInstance.isBeginning);
      setIsAtEnd(swiperInstance.isEnd);
  
      // Increment the active index by 5 on each slide change
      setActiveIndex((prevIndex) => prevIndex + 5);
    }
  };
  const mobile = useIsMobile();
  const swiperRef = useRef(null);
   const [isAtStart, setIsAtStart] = useState(true);
	   const [isAtEnd, setIsAtEnd] = useState(false);
     function formatDate(dateString) {
      const datePart = dateString;
  
      // Split the date string into an array [YYYY, MM, DD]
      const [year, month, day] = datePart.split("-");
  
      // Return the formatted date as DD/MM/YYYY
      return `${day}-${month}-${year}`;
    }
  return (
    <div>
      {type === "Static" ? (
        <div className="grid grid-cols-12">
          {data?.slice(0, column)?.map((item, index) => (
    <div
      className="mb-10"
      id="topicsMap"
      style={{
        gridColumn: `span ${gridCol[column]} / span ${gridCol[column]}`,
      }}
              key={index}
    >
      <TopicSlide
        showHeading={showHeading}
        Id={item?._id}
                key={item?._id}
                imgSrc={
                  image ? mobile ? item?.images?.mob_thumb : item?.images?.desk_thumb : null
                }
                cta={cta?.isCta}
                alt_text={mobile ? item?.images?.mob_thumb_alt1 : item?.images?.desk_thumb_alt1}
                videoSrc={item?.url}
                cardTitle={item?.primary_details?.title}
                cardDate={item?.createdAt}
                cardDescription={item?.primary_details?.desc || item?.desc}
                cardLink={item?.primary_details?.slug}
                cardBtnText={Constants.homePage.SEE_DETAILS}
                date={
                  item?.primary_details?.date &&
                  formatDate(item?.primary_details?.date)
                }
                date_enabled={item?.primary_details?.date_enabled}
      />
    </div>
          ))}
        </div>
      ) : (
        <div className="topic-slider-container" style={{ position: "relative" }}>
        {/* Left Arrow */}
        {!isAtStart && (
          <button
            className="swiper-button-prev"
            style={{
              left: mobile ? "0px" : "-50px",
              position: "absolute",
              top: "25%",
              transform: "translateY(-50%)",
              zIndex: 2,
              cursor: "pointer",
              borderRadius: "50%",
              fontSize: mobile ? "18px" : "24px",
            }}
            onClick={() => swiperRef.current?.slidePrev()}
          >
            ❮
          </button>
        )}
  
        {/* Right Arrow */}
        {!isAtEnd && (
          <button
            className="swiper-button-next"
            style={{
              right: mobile ? "-5px" : "-10px",
              position: "absolute",
              top: "25%",
              transform: "translateY(-50%)",
              zIndex: 10,
              cursor: "pointer",
              borderRadius: "50%",
              fontSize: mobile ? "18px" : "24px",
            }}
            onClick={() => swiperRef.current?.slideNext()}
          >
            ❯
          </button>
          )}
    <Swiper
    onSwiper={(swiper) => {
      swiperRef.current = swiper;
      setIsAtStart(swiper.isBeginning); // Initialize at the start
      setIsAtEnd(swiper.isEnd); // Initialize at the end if needed
    }}
    spaceBetween={20}
    slidesPerView={4}
    scrollbar={{ draggable: true }} // Enable a draggable scrollbar
    freeMode={true} // Enable free scrolling
    breakpoints={{
      0: { slidesPerView: 1 }, // Mobile: 1 slide (0 to 639px)
      640: { slidesPerView: 2 }, // Tablet: 2 slides (640 to 767px)
      768: { slidesPerView: 3 }, // Medium devices: 3 slides (768 to 1023px)
      1024: { slidesPerView: 4 }, // Large devices: 4 slides (1024px and above)
    }}
  onSlideChange={handleSlideChange}
  >
        {displayedData?.map((item,i) => (
          <>
        <SwiperSlide key={i} className="swiper-model">
        <TopicSlide
          showHeading={showHeading}
          key={item?._id}
              imgSrc={
              image ? mobile ? item?.images?.mob_thumb : item?.images?.desk_thumb : null
                }
                cta={cta?.isCta}
              videoSrc={item?.url}
          cardTitle={item?.primary_details?.title}
              cardDate={item?.createdAt}
              cardDescription={item?.primary_details?.desc || item?.desc}
          cardLink={item?.primary_details?.slug}
          cardBtnText={Constants.homePage.SEE_DETAILS}
          date={
            item?.primary_details?.date &&
            formatDate(item?.primary_details?.date)
          }
          date_enabled={item?.primary_details?.date_enabled}
        />
        </SwiperSlide></>
      ))}
    
  </Swiper>
  </div>
      )}
      </div>
  );
}
