import React, { useEffect, useState } from "react";
import CommonHomeCard from "./commonHomeCard";
import { configuration } from "../helpers/config";
import { useRouter } from "next/router";
// Banner component to show the 3 banner layout in homepage.
export default function HomeBanners({
  banners,
  banner1ThemeData,
  banner2ThemeData,
  banner3ThemeData,
}) {
  const router = useRouter();
  const pathNames = router.asPath;

  return (
    <>
      <div className="relative container-fluid">
        <div className="banner-container">
          <div className="banner_slide">
            <div className="banner_slide--left">
              {/*commonhomecard component to show individual banners */}
              <CommonHomeCard
                Id ="HomePageBannerOne"
                cardClassName={"banner_card"}
                imgSrc={
                  banners?.[0]?.imageStatic 
                    || (banners?.[0]?.image && `${configuration.IMG_URL}${banners[0].image}`)
                }
                alt_text={banners?.[0]?.alt_tag_desktop1 || banners?.[0]?.alt_text_desktop}
                imgWidth={banners?.[1] ? 960 : 1928}
                imgHeight={1070}
                imgMobileSrc={
                  banners?.[0]?.imageStatic 
                    || (banners?.[0]?.image_mob && `${configuration.IMG_URL}${banners[0].image_mob}`)
                }
                alt_text_mob={banners?.[0]?.alt_tag_mobile || banners?.[0]?.alt_text_mobile}
                imgMobileWidth={768}
                imgMobileHeight={1230}
                cardTitle={banners?.[0]?.title}
                cardDescription={banners?.[0]?.desc}
                cardBtnText={banners?.[0]?.ctaText}
                cardLink={banners?.[0]?.ctaLink}
                btnType={
                  banner1ThemeData?.ctaColor === "#001151"
                    ? "button_dark_arrow"
                    : "button_light_arrow"
                }
                isBannerClickable={banners?.[0]?.isBannerClickable}
                bannerTheme={banner1ThemeData}
                /* contentBoxClass={
                  banners ? "md:bottom-[190px]" : "bottom-[60px]"
                } */
                contentBoxClass={
                  pathNames === "/" ? "md:bottom-[80px]" : "bottom-[60px]"
                }
                target={banners?.[0]?.target}
                firstBannerstyle={"md:mb-[-100px]"}
              />
            </div>

            <div className="banner_slide--right">
              {banners?.[1] && (
                <CommonHomeCard
                Id ="HomePageBannerTwoLob2"
                  cardClassName={"banner_card"}
                  imgSrc={
                    banners?.[1]?.imageStatic 
                      || (banners?.[1]?.image && `${configuration.IMG_URL}${banners[1].image}`)
                  }
                  alt_text={banners?.[1]?.alt_tag_desktop1 || banners?.[1]?.alt_text_desktop}
                  imgWidth={banners?.[2] ? 960 : 936}
                  imgHeight={banners?.[2] ? 535 : 1070}
                  imgMobileSrc={
                    banners?.[1]?.imageStatic 
                      || (banners?.[1]?.image_mob && `${configuration.IMG_URL}${banners[1].image_mob}`)
                  }
                  alt_text_mob={banners?.[1]?.alt_tag_mobile || banners?.[1]?.alt_text_mobile}
                  imgMobileWidth={768}
                  imgMobileHeight={900}
                  cardTitle={banners?.[1]?.title}
                  cardDescription={banners?.[1]?.desc}
                  cardBtnText={banners?.[1]?.ctaText}
                  cardLink={banners?.[1]?.ctaLink}
                  btnType={
                    banner2ThemeData?.ctaColor === "#001151"
                      ? "button_dark_arrow"
                      : "button_light_arrow"
                  }
                  isBannerClickable={banners?.[1]?.isBannerClickable}
                  bannerTheme={banner2ThemeData}
                  target={banners?.[1]?.target}
                  /* contentBoxClass={
                    banners?.[2]? "md:bottom-[60px]" : "bottom-[60px]"
                  } */
                  contentBoxClass={
                    "bottom-[60px]"
                  }
                  readmore = {false}
                />
              )}

              {banners?.[2] && (
                <CommonHomeCard
                Id ="HomePageBannerThreeLob3"
                  cardClassName={"banner_card"}
                  imgSrc={
                    banners?.[2]?.imageStatic 
                      || (banners?.[2]?.image && `${configuration.IMG_URL}${banners[2].image}`)
                  }
                  alt_text={banners?.[2]?.alt_tag_desktop1 || banners?.[2]?.alt_text_desktop}
                  imgWidth={banners?.[2] ? 960 : 936}
                  imgHeight={banners?.[2] ? 535 : 1070}
                  imgMobileSrc={
                    banners?.[2]?.imageStatic 
                      || (banners?.[2]?.image_mob && `${configuration.IMG_URL}${banners[2].image_mob}`)
                  }
                  alt_text_mob={banners?.[2]?.alt_tag_mobile || banners?.[2]?.alt_text_mobile}
                  imgMobileWidth={768}
                  imgMobileHeight={900}
                  cardTitle={banners?.[2]?.title}
                  cardDescription={banners?.[2]?.desc}
                  cardBtnText={banners?.[2]?.ctaText}
                  cardLink={banners?.[2]?.ctaLink}
                  btnType={
                    banner3ThemeData?.ctaColor === "#001151"
                      ? "button_dark_arrow"
                      : "button_light_arrow"
                  }
                  isBannerClickable={banners?.[2]?.isBannerClickable}
                  bannerTheme={banner3ThemeData}
                  /* contentBoxClass={
                    banners?.[2] ? "md:bottom-[190px]" : "bottom-[60px]"
                  } */
                  contentBoxClass={
                    pathNames === "/" ? "md:bottom-[73px]" : "bottom-[60px]"
                  }
                  target={banners?.[2]?.target}
                  readmore = {false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
